import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { NgClass } from '@angular/common';
import { CloseModalSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-close-modal',
	standalone: true,
	templateUrl: './close-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CloseModalSvgComponent, NgClass],
})
export class CloseModalComponent {
	customStyles = input<string>();

	close = output<void>();

	constructor(private modalSrv: ModalService) {}

	closeModalPanel() {
		this.close.emit();
		this.modalSrv.close();
	}
}
