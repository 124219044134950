<div [formGroup]="parentForm">
	<div
		formGroupName="phoneDetailsForm"
		class="flex border rounded-lg shadow-sm"
		[ngClass]="{
			'border-gray-300': showDarkBorder,
			'border-red-600':
				formSubmitted &&
				(phone.hasError('phoneInvalid') ||
					phone.hasError('required') ||
					countryCode.hasError('required')),
		}"
	>
		<uc-phone-country-code-field
			formControlName="countryCode"
			controlName="countryCode"
			[formSubmitted]="formSubmitted"
		>
		</uc-phone-country-code-field>
		<!--
			note: formSubmitted is set to false
			so only the error messages within the phone-field.component below get triggered -->
		<uc-input-field
			formControlName="phone"
			inputType="tel"
			controlName="phone"
			[placeholder]="placeholder"
			autocomplete="tel"
			labelText="Mobile Number"
			class="w-full"
			customStyles="!border-l-0 !rounded-r-md !rounded-l-none !shadow-none !border-0"
			[formSubmitted]="false"
		></uc-input-field>
	</div>
</div>

<uc-form-error
	fieldError="Invalid phone number. Please enter a valid phone number between 4 and 15 digits (numbers only)."
	[formError]="
		formSubmitted && phone.hasError('phoneInvalid') && !phone.hasError('required')
	"
></uc-form-error>

<uc-form-error
	fieldError="Please enter your phone number."
	[formError]="formSubmitted && phone.hasError('required')"
>
</uc-form-error>

<uc-form-error
	fieldError="Please select a country calling code."
	[formError]="formSubmitted && countryCode.hasError('required')"
>
</uc-form-error>
